import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index'

const userDefaults = {
  uid: 0,
  displayName: '',
  about: '',
  photoURL: '@/assets/images/portrait/small/avatar-s-11.jpg',
  status: '',
  userRole: '',
}

const profileInfoLocalStorage = JSON.parse(window.localStorage.getItem('profile')) || {}
const userInfoLocalStorage = JSON.parse(window.localStorage.getItem('userInfo')) || {}
const userRoles = JSON.parse(window.localStorage.getItem('user_roles')) || {}

const getRoles = () => {
  const roles = {}
  Object.keys(userRoles).forEach(key => {
    if (roles[key] === undefined && userRoles[key] !== null) roles[key] = userRoles[key]
  })

  return roles
}

const getUserInfo = () => {
  const userInfo = {}

  Object.keys(userDefaults).forEach(key => {
    userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key]
  })

  Object.keys(userInfoLocalStorage).forEach(key => {
    if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
  })

  return userInfo
}

const getProfileInfo = () => {
  const profileInfo = {}

  Object.keys(userDefaults).forEach(key => {
    profileInfo[key] = profileInfoLocalStorage[key] ? profileInfoLocalStorage[key] : userDefaults[key]
  })

  Object.keys(profileInfoLocalStorage).forEach(key => {
    if (profileInfo[key] === undefined && profileInfoLocalStorage[key] !== null) profileInfo[key] = profileInfoLocalStorage[key]
  })

  return profileInfo
}

const getCounts = () => {
  const counts = {
    employees_count: 0,
    consultants_count: 0,
    prospects_count: 0,
    vendors_count: 0,
    jobs_count: 0,
    endclients_count: 0,
    employers_count: 0,
    suppliers_count: 0,
    referrers_count: 0,
    subvendors_count: 0,
    payable_pending_count: 0,
    payable_pending_amount: 0,
    receivable_pending_count: 0,
    receivable_pending_amount: 0,
    timesheets_waiting_count: 0,
    timesheets_received_count: 0,
    upcomming_interviews_count: 0,
    pending_tasks_count: 0,
  }
  return counts
}

const getMonthConfig = () => {
  const configs = {
    plugins: [
      monthSelectPlugin({
        shorthand: true,
        dateFormat: 'M-Y',
        altFormat: 'F, Y',
      }),
    ],
  }
  return configs
}

const getTillNowMonthConfig = () => {
  const configs = {
    plugins: [
      monthSelectPlugin({
        shorthand: true,
        dateFormat: 'M-Y',
        altFormat: 'F, Y',
      }),
    ],
    maxDate: new Date(),
    minDate: new Date(2010, 1, 1),
  }
  return configs
}

const getDateConfig = () => {
  const configs = {
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'm-d-Y',
    altInput: true,
    dateFormat: 'm-d-Y',
    minDate: new Date(2000, 1, 1),
  }
  return configs
}

const getMaxDateConfig = () => {
  const configs = {
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'm-d-Y',
    altInput: true,
    dateFormat: 'm-d-Y',
    maxDate: new Date(),
    minDate: new Date(1900, 1, 1),
  }
  return configs
}

// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = query => window.matchMedia(query).matches

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

const state = {
  AppActiveUser: getUserInfo(),
  company_name: '',
  company_logo: '',
  profile: getProfileInfo(),
  roles: getRoles(),
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  isTouchDevice: isTouchDevice(),
  verticalNavMenuWidth: 'default',
  verticalNavMenuItemsMin: false,
  scrollY: 0,
  monthConfig: getMonthConfig(),
  maxMonthConfig: getTillNowMonthConfig(),
  config: getDateConfig(),
  notGraterThanToday: getMaxDateConfig(),
  query: {
    activeTab: 'Monthly', page: 1, month: null, startdate: null, endDate: null, query: '', innerpage: 1,
  },
  counts: getCounts(),
  selectedUser: {},
  taskDetails: {},
  windowWidth: null,
}

export default state
