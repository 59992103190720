import axios from 'axios'
import User from '@/js/user'
import Constant from '@/js/constant'
import store from '@/store/store'
import router from '@/router'

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const HOST = Constant.hosts.url
const LOGIN_URL = 'oauth/token'

const instance = axios.create({
  baseURL: HOST,
  timeout: 100000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
})

export default {
  login(context1, creds) {
    const context = context1
    context.$set(context, 'loadings', true)
    localStorage.removeItem('logoutReason')
    context.error = ''
    const data = creds
    data.grant_type = 'password'
    data.client_id = 'external_web_client'
    data.client_secret = 'UowB9J1Q4SViGpPl5YqcmauHmZDv5dDMfmz56GnTDecW9SzTd19sAY2FPc1hdni3'
    data.redirect_uri = 'https://eprm.com'
    const rawData = []
    Object.keys(data).forEach(key => rawData.push(`${key}=${data[key]}`))
    const body = rawData.join('&')
    context.$vs.loading()
    instance.post(LOGIN_URL, body).then(response => {
      context.$vs.loading.close()
      localStorage.setItem('external_access_token', response.data.access_token)
      localStorage.setItem('logged_email', creds.username)
      User.getProfile(context, response)
    }, () => {
      context.$vs.loading.close()
      context.$swal({
        title: 'Login',
        text: 'Invalid login details',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    })
  },
  adminlogin(context1, creds) {
    const context = context1
    localStorage.removeItem('logoutReason')
    context.error = ''
    const data = creds
    data.grant_type = 'password'
    data.client_id = 'external_web_admin'
    data.client_secret = '2BYU9Xh6JiXEyJu1YmQSbIuHmZDv5dDMfmz56GnTDecW9SzTd19sAY2FPc1hdni3'
    data.redirect_uri = 'https://eprm.com'
    const rawData = []
    Object.keys(data).forEach(key => rawData.push(`${key}=${data[key]}`))
    const body = rawData.join('&')
    context.$vs.loading()
    instance.post(LOGIN_URL, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }).then(response => {
      context.$vs.loading.close()
      localStorage.setItem('external_access_token', response.data.access_token)
      localStorage.setItem('logged_email', creds.username)

      User.getProfile(context, response)
    }, error => {
      context.$vs.loading.close()
      context.error = error.data.error_description
      context.loggedInFailure()
    })
  },
  logOut() {
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('userInfo')
    store.state.AppActiveUser = {}
    store.commit('UPDATE_USER_INFO', {})
    router.push('/login').catch(() => {})
  },
  isAuthenticated() {
    if (store.state.AppActiveUser.access_token !== null && store.state.AppActiveUser.access_token !== undefined) {
      return true
    }
    return false
  },
  getAuthHeader() {
    return `${'Bearer'}localStorage.getItem('external_access_token')`
  },
}
