import Constants from '@/js/constant'
import store from '@/store/store'
import axios from 'axios'

const HOST = Constants.hosts.url
const USER_LIST = `${HOST}profile`

export default {
  getProfile(context1, data) {
    const context = context1
    context.$vs.loading()
    axios.get(USER_LIST, { headers: { Authorization: `Bearer ${data.data.access_token}` } }).then(response => {
      context.$vs.loading.close()
      store.commit('access_token', data.data.access_token)
      store.commit('UPDATE_PROFILE', response.data)
      store.commit('UPDATE_USER_INFO', {
        displayName: response.data.name, email: response.data.email, access_token: data.data.access_token, photoURL: response.data.profile_pic, companyLogo: response.data.company_logo,
      })
      axios.defaults.headers.common.Authorization = `Bearer ${data.data.access_token}`
      if (response.data.timesheet_provider_parents.length > 0) {
        window.location.hash = '#/timesheets'
      } else if (Object.keys(response.data.invoice_parents).length >= 1) {
        window.location.hash = '#/invoices'
      } else if (Object.keys(response.data.job_parent).length >= 1) {
        window.location.hash = '#/jobs'
      } else if (response.data.interviewer_exist) {
        window.location.hash = '#/my-interviews'
      }
    }, () => {
      this.$vs.loading.close()
    })
  },
  getProfile1(context1) {
    const context = context1
    context.$vs.loading()
    axios.get(USER_LIST, { headers: { Authorization: `Bearer ${context.$store.state.AppActiveUser.access_token}` } }).then(response => {
      context.$vs.loading.close()
      store.commit('UPDATE_PROFILE', response.data)
      store.commit('UPDATE_USER_INFO', {
        displayName: response.data.name, email: response.data.email, access_token: context.$store.state.AppActiveUser.access_token, photoURL: response.data.profile_pic, companyLogo: response.data.company_logo,
      })
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`
    }, () => {
      context.$vs.loading.close()
    })
  },
  profile() {
    return store.state.profile
  },
  logout() {
    localStorage.removeItem('external_access_token')
    localStorage.removeItem('profile')
    localStorage.removeItem('old_access_token')
    localStorage.removeItem('old_dashboard_type')
    localStorage.removeItem('old_profile')
    localStorage.removeItem('user-profiles')
    localStorage.removeItem('selected-user')
    store.user.authenticated = false
    window.location.hash = '#/login'
  },
}
