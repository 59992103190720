import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import money from 'v-money'
import Constant from '@/js/constant'
import axios from 'axios'
import auth from '@/auth/authService'
import User from '@/js/user'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import './global-components'
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select'
import '@/libs/sweet-alerts'
import MarqueeText from 'vue-marquee-text-component'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import Vue2Filters from 'vue2-filters'
import * as VeeValidate from 'vee-validate'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.use(Vuesax)
extendMoment(Moment)

axios.defaults.baseURL = Constant.hosts.url
axios.defaults.timeout = 18000000
axios.defaults.maxContentLength = 200000000
axios.defaults.maxBodyLength = 200000000

Vue.use(Vue2Filters)
Vue.use(VeeValidate)
Vue.use(money, { precision: 4 })
Vue.use(Vuesax)

Vue.filter('toCurrency', value => {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})

axios.interceptors.request.use(config1 => {
  const config = config1
  let company = window.location.hostname
  company = company.substring(company.indexOf('.') + 1)
  company = company.substring(0, company.indexOf('.'))
  config.headers.common.dns = company
  if (store.state.AppActiveUser.access_token !== undefined && !config.url.includes('password')) {
    if (store.state.AppActiveUser.access_token !== null) {
      config.headers.common.Authorization = `Bearer ${store.state.AppActiveUser.access_token}`
      axios.defaults.headers.common.Authorization = `Bearer ${store.state.AppActiveUser.access_token}`
    } else {
      config.headers.common.Authorization = null
      axios.defaults.headers.common.Authorization = null
    }
  } else {
    config.headers.common.Authorization = null
    axios.defaults.headers.common.Authorization = null
  }
  return config
}, error => Promise.reject(error))

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      auth.logOut()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  },
)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Marquee Text
Vue.component('marquee-text', MarqueeText)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (!(from.path.includes(to.path) || to.path.includes(from.path))) {
    store.state.query = {
      activeTab: 'Monthly', page: 1, month: null, startdate: null, endDate: null, query: '', innerpage: 1,
    }
  }
  if (auth.isAuthenticated() && ((to.path === '/login') || (to.path === '/forgot-password') || (to.path === '/intrested') || (to.path === '/intake-form') || (to.path === '/reset-password') || (to.path === '/admin-login'))) {
    document.body.className = 'horizontal-menu navbar-sticky'
    if (User.profile() !== null) {
      if (User.profile().timesheet_provider_parents.length > 0) {
        next({ path: '/timesheets' })
      } else if (User.profile().invoice_exist) {
        next({ path: '/invoices' })
      } else if (User.profile().job_exist) {
        next({ path: '/jobs' })
      } else if (User.profile().interviewer_exist) {
        next({ path: '/my-interviews' })
      }
    }
  }
  if (!auth.isAuthenticated() && (to.path !== '/admin-login' && (to.path !== '/login' && !to.path.includes('/create-password/') && !to.path.includes('/forgot-password') && !to.path.includes('/intrested') && !to.path.includes('/intake-form') && !to.path.includes('/reset-password') && !to.path.includes('/activate-status/')))) {
    next({ path: '/login' })
    document.body.className = 'login'
  }
  if (!auth.isAuthenticated() && ((to.path === '/login') || to.path.includes('/create-password/') || (to.path === '/forgot-password') || (to.path === '/intake-form') || (to.path === '/intrested') || (to.path === '/reset-password') || (to.path.includes('/activate-status/')) || (to.path === '/admin-login'))) {
    document.body.className = 'login'
    next()
  }
  if (to.path.includes('/create-password/')) {
    document.body.className = 'login'
    localStorage.removeItem('external_access_token')
    localStorage.removeItem('profile')
    localStorage.removeItem('old_access_token')
    localStorage.removeItem('old_dashboard_type')
    localStorage.removeItem('old_profile')
    localStorage.removeItem('user-profiles')
    localStorage.removeItem('selected-user')
    if (auth.user !== null && auth.user !== undefined) {
      auth.user.authenticated = false
    }
    next()
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
