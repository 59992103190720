import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // *------------ Timesheets --------------------*
    {
      path: '/timesheets',
      name: 'timesheets',
      component: () => import('@/views/timesheets/Timesheets.vue'),
    },
    {
      path: '/timesheets/new',
      name: 'timesheets-new',
      component: () => import('@/views/timesheets/actions/New.vue'),
      meta: {
        pageTitle: 'Timesheets',
        breadcrumb: [
          {
            text: 'Timesheet Management System',
            active: true,
          },
        ],
        navActiveLink: 'timesheets',
      },
    },
    {
      path: '/timesheets/edit/:id',
      name: 'timesheets-edit',
      component: () => import('@/views/timesheets/actions/Edit.vue'),
      meta: {
        pageTitle: 'Timesheets',
        breadcrumb: [
          {
            text: 'Timesheet Management System',
            active: true,
          },
        ],
        navActiveLink: 'timesheets',
      },
    },
    {
      path: '/timesheets/view/:id',
      name: 'timesheets-view',
      component: () => import('@/views/timesheets/actions/View.vue'),
      meta: {
        pageTitle: 'Timesheets',
        breadcrumb: [
          {
            text: 'Timesheet Management System',
            active: true,
          },
        ],
        navActiveLink: 'timesheets',
      },
    },
    // *------------ Invoices --------------------*
    {
      path: '/invoices',
      name: 'invoices',
      component: () => import('@/views/invoices/Invoices.vue'),
    },
    {
      path: '/invoices/new',
      name: 'invoices-new',
      component: () => import('@/views/invoices/actions/New.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoice Management System',
            active: true,
          },
        ],
        navActiveLink: 'invoices',
      },
    },
    {
      path: '/invoices/edit/:id',
      name: 'invoices-edit',
      component: () => import('@/views/invoices/actions/Edit.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoice Management System',
            active: true,
          },
        ],
        navActiveLink: 'invoices',
      },
    },
    {
      path: '/invoices/view/:id',
      name: 'invoices-view',
      component: () => import('@/views/invoices/actions/View.vue'),
      meta: {
        pageTitle: 'Invoices',
        breadcrumb: [
          {
            text: 'Invoice Management System',
            active: true,
          },
        ],
        navActiveLink: 'invoices',
      },
    },
    // *------------ Jobs --------------------*
    {
      path: '/jobs',
      name: 'jobs',
      component: () => import('@/views/jobs/Jobs.vue'),
    },
    {
      path: '/jobs/:job_id/jobs/view',
      name: 'jobs-view',
      component: () => import('@/views/jobs/actions/View.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/jobs/view-employee',
      name: 'jobs-view-employee',
      component: () => import('@/views/jobs/actions/ViewEmployee.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/candidates',
      name: 'candidates',
      component: () => import('@/views/candidates/Candidates.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/candidates/new',
      name: 'candidates-new',
      component: () => import('@/views/candidates/actions/New.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/candidates/edit/:id',
      name: 'candidates-edit',
      component: () => import('@/views/candidates/actions/Edit.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/candidates/view/:id',
      name: 'candidates-view',
      component: () => import('@/views/candidates/actions/View.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/refer-candidates/new',
      name: 'refer-candidates-new',
      component: () => import('@/views/candidates/actions/NewRefer.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    {
      path: '/jobs/:job_id/existing-candidates',
      name: 'existing-candidates',
      component: () => import('@/views/candidates/actions/ExistingCandidates.vue'),
      meta: {
        navActiveLink: 'jobs',
      },
    },
    // *------------ Requests --------------------*
    {
      path: '/requests',
      name: 'requests',
      component: () => import('@/views/requests/Requests.vue'),
    },
    {
      path: '/requests/new',
      name: 'requests-new',
      component: () => import('@/views/requests/actions/New.vue'),
      meta: {
        navActiveLink: 'requests',
      },
    },
    {
      path: '/requests/view/:id',
      name: 'requests-view',
      component: () => import('@/views/requests/actions/View.vue'),
      meta: {
        navActiveLink: 'requests',
      },
    },
    // *------------ My Interviews --------------------*
    {
      path: '/my-interviews',
      name: 'my-interviews',
      component: () => import('@/views/my-interviews/MyInterviews.vue'),
    },
    {
      path: '/my-interviews/edit',
      name: 'my-interviews-edit',
      component: () => import('@/views/my-interviews/actions/Edit.vue'),
      meta: {
        navActiveLink: 'my-interviews',
      },
    },
    {
      path: '/my-interviews/dashboard',
      name: 'my-interviews-dashboard',
      component: () => import('@/views/my-interviews/actions/InterviewersDashboard.vue'),
      meta: {
        navActiveLink: 'my-interviews',
      },
    },
    // *------------ Pages --------------------*
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/admin-login',
      name: 'admin-login',
      component: () => import('@/views/pages/AdminLogin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/activate-status/:token',
      name: 'active-status',
      component: () => import('@/views/pages/ActiveStatus.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import('@/views/pages/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/create-password/:token',
      name: 'create-password',
      component: () => import('@/views/pages/CreatePassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/intake-form/:token',
      name: 'intake-form',
      component: () => import('@/views/pages/IntakeForm.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/intrested/:token',
      name: 'intrested',
      component: () => import('@/views/pages/Intrest.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
