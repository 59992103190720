import axios from 'axios'
import Constants from '@/js/constant'

const HOST = Constants.hosts.hrurl
const job = `${HOST}jobs`

export default {
  getJobs(context1) {
    const context = context1
    const params = {
      params: {
        query: context.$store.state.query.query, page: context.$store.state.query.page - 1, user_id: context.employerId, type_id: context.type,
      },
    }
    axios.get(`${job}/external`, params).then(response => {
      context.setJobs(response.data)
    }, () => {
    })
  },
  getFullList(context1) {
    const context = context1
    const params = {}
    axios.get(`${job}/external/fulllist`, params).then(response => {
      context.setJobs(response.data)
    }, () => {
    })
  },
  getFullListNOReturn(context1) {
    const context = context1
    const params = {}
    axios.get(`${job}/external/fulllist`, params).then(response => {
      context.$store.state.profile.jobs_count = response.data.count
    }, () => {
    })
  },

  getRequest(context1) {
    const context = context1
    const params = {}
    axios.get(`${job}/request`, params).then(response => {
      context.setJobs(response.data)
    }, () => {
    })
  },
  addJob(context1) {
    const context = context1
    context.$vs.loading()
    axios.post(job, context.addJob).then(response => {
      context.$vs.loading.close()
      context.jobAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotAdded(error.response.data.return_message)
    })
  },
  updateJob(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.put(`${job}/${id}`, context.addJob).then(response => {
      context.$vs.loading.close()
      context.jobUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${job}/${id}`).then(response => {
      context.$vs.loading.close()
      context.jobDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotDeleted(error.response.data.return_message)
    })
  },
  getJobById(context1, id, type) {
    const context = context1
    const params = { query: context.searchField, type_id: type }
    context.$vs.loading()
    axios.get(`${job}/${id}`, params).then(response => {
      context.$vs.loading.close()
      context.setJob(response.data)
    }, error => {
      context.$vs.loading.close()
      context.jobNotFound(error.response.data.return_message)
    })
  },

}
