import moment from 'moment'

export default {
  hosts: {
    url: 'https://1xg1eluc8f.execute-api.us-east-2.amazonaws.com/prod-all/',
    // vjmsUrl: 'http://3.22.101.175:9070/',
    hrurl: 'https://v0uucq4f96.execute-api.us-east-2.amazonaws.com/prod-hr/hr/',
    faurl: 'https://g8tt5l9crj.execute-api.us-east-2.amazonaws.com/prod-fa/fa/',
    adurl: 'https://ukjvuec0na.execute-api.us-east-2.amazonaws.com/prod-ad/ad/',
  },
  convertObjectToString(obj) {
    return JSON.stringify(obj)
  },
  getEprmMonthDateFromString(dateStr) {
    const momenta = moment.isMoment(dateStr)
    if (momenta) {
      const obj = { date: dateStr.date(), month: dateStr.month() + 1, year: dateStr.year() }
      return obj
    }
    if (dateStr === '') {
      return null
    }
    const parts = dateStr.split('-')
    const obj = { date: parts[1], month: parts[0], year: parts[2] }
    return obj
  },
  getDateStringFromEprmDate(date) {
    const dateString = `${date.month}-${date.date}-${date.year}`
    return dateString
  },
  getPaymentStatus(minutes) {
    if (typeof minutes === 'undefined' || minutes === null) return 'btn-default'
    return 'btn-primary'
  },
  getRequestTypes(context1, isTimesheetExist, isJobExist, isInvoiceExist) {
    const context = context1
    const timesheetRequest = {
      type: 'timesheet',
      label: 'Timesheet',
    }
    const invoiceRequest = {
      type: 'invoice',
      label: 'Invoice',
    }
    const jobRequest = {
      type: 'job',
      label: 'Job',
    }
    const requestTypes = []
    if (isTimesheetExist) {
      requestTypes.push(timesheetRequest)
    }
    if (isJobExist) {
      requestTypes.push(invoiceRequest)
    }
    if (isInvoiceExist) {
      requestTypes.push(jobRequest)
    }
    context.requestTypes = requestTypes
  },
  getEmployerTypes(context1) {
    const context = context1
    const employerTypes = [
      {
        id: '2',
        name: 'Employer',
      },
      {
        id: '4',
        name: 'Vendor',
      },
      {
        id: '5',
        name: 'Sub Vendor',
      },
      {
        id: '6',
        name: 'Supplier',
      },
    ]
    context.types = employerTypes
  },
  getAfterBeforeDays(context1) {
    const context = context1
    const afterBeforeDays = [
      {
        id: '1',
        name: '1',
      },
      {
        id: '2',
        name: '2',
      },
      {
        id: '3',
        name: '3',
      },
    ]
    context.days = afterBeforeDays
  },
  getStatusListReturn(context1) {
    const context = context1
    const predefinedList = [
      {
        id: '1',
        name: 'Active',
      },
      {
        id: '2',
        name: 'Revoke',
      },
    ]
    context.status_list = predefinedList
  },
  tickFunction(context1) {
    const context = context1
    context.count += 1
  },

}
