const mutations = {
  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val
  },
  UPDATE_COMPANY_NAME(state, val) {
    state.company_name = val
  },
  UPDATE_COMPANY_LOGO(state, val) {
    state.company_logo = val
  },
  UPDATE_PROFILE(state, payload) {
    state.profile = payload
    window.localStorage.setItem('profile', JSON.stringify(payload))
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width
  },
  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList.pages.data.findIndex(item => item.url === payload.url)

    // update the main list
    state.navbarSearchAndPinList.pages.data[index].is_bookmarked = payload.val

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.pages.data[index])
    } else {
      // find item index from starred pages
      const index1 = state.starredPages.findIndex(item => item.url === payload.url)

      // remove item using index
      state.starredPages.splice(index1, 1)
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10)
    state.starredPages = list.concat(starredPagesMore)
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false
    const lastItemInStarredLimited = state.starredPages[10]
    const starredPagesLimited = state.starredPages.slice(0, 10)
    state.starredPages = starredPagesLimited.concat(list)

    downToUp = state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) return true
      return false
    })

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited)
    }
  },

  TOGGLE_CONTENT_OVERLAY(state, val) { state.bodyOverlay = val },
  UPDATE_PRIMARY_COLOR(state, val) { state.themePrimaryColor = val },
  UPDATE_THEME(state, val) { state.theme = val },
  UPDATE_WINDOW_WIDTH(state, width) { state.windowWidth = width },
  UPDATE_WINDOW_SCROLL_Y(state, val) { state.scrollY = val },

  USER_ROLES(state, payload) {
    const roles = JSON.parse(window.localStorage.getItem('user_roles')) || state.roles

    Object.keys(payload).forEach(property => {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.roles[property] = payload[property]

        roles[property] = payload[property]
      }
    })
    // Store data in localStorage
    window.localStorage.setItem('user_roles', JSON.stringify(roles))
  },

  UPDATE_USER_INFO(state, payload) {
    const userInfo = JSON.parse(window.localStorage.getItem('userInfo')) || state.AppActiveUser

    Object.keys(payload).forEach(property => {
      if (payload[property] !== null) {
        state.AppActiveUser[property] = payload[property]

        userInfo[property] = payload[property]
      }
    })
    // Store data in localStorage
    window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
}

export default mutations
